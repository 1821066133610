export const EMAIL_JS_SERVICE_ID = 'service_b4z7nef';
export const EMAIL_JS_TEMPLATE_ID = 'template_9dhbx0j';
export const EMAIL_JS_PUBLIC_KEY = 'L-SmAKokSLZpdAJCQ';

export const RECAPTCHA_SITE_KEY = '6LdbAxUqAAAAACJTGDcsguYPBDr9PL2KgXnTqdhg';

export const SUCCESS_MESSAGE =
  'Danke für deine Nachricht! Wir werden uns so bald wie möglich um dein Anliegen kümmern. Liebe Grüße, das Nana Team';
export const ERROR_ALERT =
  'Es gab ein Problem beim Senden deiner Nachricht. Bitte versuche es später erneut.';

export const FORM_ERRORS = {
  name: 'Bitte gib deinen Namen ein.',
  message: 'Bitte gib eine Nachricht ein.',
  captcha: 'Bitte verifiziere, dass du kein Roboter bist.',
};

export const FORM_LABELS = {
  name: 'Dein Name',
  phone: 'Deine Telefonnummer (optional)',
  email: 'Deine E-Mail (optional)',
  message: 'Deine Nachricht',
};

export const FORM_PLACEHOLDERS = {
  name: 'Name eingeben',
  phone: 'Telefonnummer eingeben',
  email: 'E-Mail eingeben',
  message: 'Nachricht eingeben',
};

export const GALLERY_MISSING_DESCRIPTION =
  'Mir fehlt eine Beschreibung. Aber das macht mich nicht traurig, ich sehe trotzdem verdammt gut aus.';

export const OPENING_HOURS = 'Montag-Samstag: 9:30 - 22:30';
export const OPENING_HOURS_SUN = 'Sonntags: 9:30 - 18:30';
