import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Gallery from '../gallery/Gallery';
import Specials from '../specials/Specials';
import specialsData from '../../data/special.json';
import galleryData from '../../data/images.json';

const SpecialsAndGallery: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(

        filter: {
          extension: { eq: "jpg" }
          sourceInstanceName: { eq: "gallery-images" }
        }
          sort: { fields: base, order: ASC }
      ) {
        edges {
          node {
          
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
            relativePath
          }
        }
      }
    }
  `);

  const galleryImages = galleryData.flatMap(imageData => {
    const imageNode = data.allFile.edges.find((edge: { node: { relativePath: string }; }) =>
      edge.node.relativePath.includes(imageData.src)
    );

    return imageNode
      ? {
        image: getImage(imageNode.node.childImageSharp.gatsbyImageData),
        description: imageData.description,
      }
      : [];
  });

  const specials = specialsData.map(special => {
    const imageNode = data.allFile.edges.find((edge: { node: { relativePath: string | string[]; }; }) =>
      edge.node.relativePath.includes(special.src)
    );

    return {
      image: getImage(imageNode?.node.childImageSharp.gatsbyImageData),
      description: special.description,
      name: special.name,
    };
  });

  return (
    <div>
      {specials.length > 0 && <Specials specials={specials} />}
      <Gallery images={galleryImages} />
    </div>
  );
};

export default SpecialsAndGallery;
