import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import { Navbar, NanaLogoWrapper } from '@components';
import { OPENING_HOURS } from '@constants/constants';

interface HeaderProps {
  openModal: (modalName: string) => void;
}

const Header: React.FC<HeaderProps> = ({ openModal }) => {
  const [visible, setVisible] = useState(false);
  const OPENING_HOURS_SUN: String = 'sonntags: 9:30 - 18:00';

  useEffect(() => {
    setVisible(true);
  }, []);

  const containerPadding = useBreakpointValue({ base: 6, lg: 8 });
  const maxContainerWidth = useBreakpointValue({
    base: 'full',
    sm: 'lg',
    md: 'xl',
    lg: '2xl',
  });
  const paddingTop = useBreakpointValue({
    base: 16,
    sm: 24,
    lg: 36,
  });
  const textGap = useBreakpointValue({
    base: 4,
    sm: 5,
    lg: 6,
  });
  const textMarginTop = useBreakpointValue({
    base: 4,
    sm: 6,
    lg: 8,
  });

  return (
    <Box bg="white">
      <Navbar openModal={openModal} />
      <Box position="relative" px={containerPadding}>
        <Box
          mx="auto"
          maxW={maxContainerWidth}
          pt={paddingTop}
          textAlign="center"
        >
          <NanaLogoWrapper />
          <Flex
            direction="column"
            align="center"
            justify="center"
            gap={textGap}
            color="gray.500"
            mt={textMarginTop}
            className={`smooth-fade-in ${visible ? 'visible' : ''}`}
          >
            <Flex align="center" mt={textMarginTop}>
              <Flex direction="column" align="center" textTransform="lowercase">
                <Text fontSize={{ base: 'lg', sm: 'xl', lg: '2xl' }} letterSpacing="4px" color="gray.400">
                  {OPENING_HOURS}
                </Text>
                <Text fontSize={{ base: 'lg', sm: 'xl', lg: '2xl' }} letterSpacing="4px" color="gray.400">
                  {OPENING_HOURS_SUN}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box >
  );
};

export default Header;
